import 'lightgallery.js';

class Gallery
{
    static getSelector()
    {
        return '.gallery';
    }

    /**
     * @param element HTMLElement
     */
    constructor(element)
    {
        lightGallery(element, {
            selector: '.gallery-item',
            download: true,
        });
    }

}

export default Gallery;
